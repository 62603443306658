import React from "react";

function Baslik() {
  return (
    <>
      <div className="baslik1">İzmir Nöbetçi Eczaneler</div>
      <h3 style={{ color: "white" }}>
        Liste Alfabetik olarak sıralanmaktadır...
      </h3>
      <h3 style={{ color: "white" }}>
        Arama kısmına yazılan kelime ile arama yapabilirsiniz.
      </h3>
    </>
  );
}

export default Baslik;
